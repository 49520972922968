
//components
import Layout from "../hocs/Layout"
import IndFinanz from "../components/finanz/indFinanz";
//react
import { useEffect } from "react";


const Financieros = ({
}) => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return(
        <Layout>
            <div className="bg-zinc-900">
                <IndFinanz/>
            </div>
        </Layout>
    )
}


export default Financieros