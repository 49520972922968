
//components
import Layout from "../hocs/Layout"
import ProxDivsTable from "../components/divids/ProxDivsTable";
//react
import { useEffect } from "react";


const Divids = ({
}) => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return(
        <Layout>
            <div className="bg-zinc-900">
                <ProxDivsTable/>
            </div>
        </Layout>
    )
}


export default Divids