import React, {useState, useEffect} from 'react';
import api from '../../Api_call';



function ProxDivsTable() {

    const [divsLoaded, setDivssLoaded] = useState(false)
    const [divsData, setDivsData] = useState(null)

    const fetchDivssData = async() => {
        const responseDivs = await api.get('/next_dividends/')
        setDivsData(responseDivs.data)
        setDivssLoaded(true)
    }
  
    const getClassReturns = (value1) => {
      var newValue = parseFloat(value1)+2
      var percentage = newValue + '%'
      return percentage; // Adjust the class for zero values
    
    };

    const getValueNoInfo = (value1) => {
      if (value1 === "NoInfo") {
        return 'No Info';
      } else {
        return value1+'%'; // Adjust the class for zero values
      }
    };
    

    useEffect(() => {
      fetchDivssData();
    },[]);


    return (
      <div className="md:max-w-7xl h-max mx-auto pt-2 md:pt-8 flex md:justify-center">
        <div className='border border-zinc-600 rounded-md w-full overflow-x-scroll md:overflow-x-auto'>
          <div className="flex text-xs lg:text-base md:grid md:grid-cols-9 md:place-content-center w-full">
              <div className="p-2 sticky md:relative left-0  flex-shrink-0 w-24 md:w-auto flex items-center justify-center border-b-2 border-zinc-600 text-zinc-200 font-extrabold text-center bg-black">
              Fecha ExDividendo
              </div>
              <div className="p-2 py-1 flex-shrink-0 md:flex-grow w-24 md:w-auto flex items-center justify-center border-b-2 border-zinc-600 text-zinc-200 font-extrabold text-center">
              Prox. Pago
              </div>
              <div className="p-2 py-1 flex-shrink-0 w-24 md:w-auto flex items-center justify-center border-b-2 border-zinc-600 text-zinc-200 font-extrabold text-center ">
              Retorno Prox. Pago
              </div>
              <div className="p-2 py-1 flex-shrink-0 w-24 md:w-auto flex items-center justify-center border-b-2 border-zinc-600 text-zinc-200 font-extrabold text-center ">
              Activo
              </div>
              <div className="p-2 py-1 flex-shrink-0 w-24 md:w-auto flex items-center justify-center border-b-2 border-zinc-600 text-zinc-200 font-extrabold text-center ">
              Precio Acción
              </div>
              <div className="p-2 py-1 flex-shrink-0 w-24 md:w-auto flex items-center justify-center border-b-2 border-zinc-600 text-zinc-200 font-extrabold text-center ">
              Dividendo por Acción
              </div>
              <div className="p-2 py-1 flex-shrink-0 w-32 md:w-auto flex items-center justify-center border-b-2 border-zinc-600 text-zinc-200 font-extrabold text-center ">
              Retorno Anual
              </div>
              <div className="p-2 py-1  flex-shrink-0 w-24 md:w-auto flex items-center justify-center border-b-2 border-zinc-600 text-zinc-200 font-extrabold text-center ">
              Retorno Medio 5 Añoss
              </div>
              <div className="p-2 py-1 flex-shrink-0 w-24 md:w-auto flex items-center justify-center border-b-2 border-zinc-600 text-zinc-200 font-extrabold text-center ">
              Payout
              </div>
          </div>
          {divsLoaded&&divsData.next_divs?
          <>
          
          {Object.keys(divsData.next_divs.bvc_query).map((key, index) => (
          <div className=" text-xs lg:text-base flex md:grid md:grid-cols-9 md:place-content-center">
            <div className="py-1 sticky md:relative flex-shrink-0 left-0 w-24 md:w-auto bg-black text-zinc-200 text-center flex items-center justify-center border-x border-zinc-700 ">{divsData.next_divs.Fecha_ExDividendo[key]}</div>
            <div className="py-1 flex-shrink-0 w-24 md:w-auto text-zinc-200 text-center flex items-center justify-center">${divsData.next_divs.Proximo_Pago_Divs[key]} COP</div>
            <div className="py-1 flex-shrink-0 w-24 md:w-auto text-zinc-200 text-center flex items-center justify-center">{divsData.next_divs.Proximo_Pago_Divs_ret[key]}%</div>
            <div className="py-1 flex-shrink-0 w-24 md:w-auto font-bold text-zinc-200 text-center flex items-center justify-center">{divsData.next_divs.bvc_query[key].toUpperCase()}</div>
            <div className="py-1 flex-shrink-0 w-24 md:w-auto text-zinc-200 text-center flex items-center justify-center">${divsData.next_divs.Ultimo_Precio[key]} COP</div>
            <div className="py-1 flex-shrink-0 w-24 md:w-auto text-zinc-200 text-center flex items-center justify-center">${divsData.next_divs.Dividendo[key]} COP</div>
            <div className='flex justify-start flex-shrink-0 w-32 md:w-auto'>
              <div className={`py-1 ml-8 font-bold 'text-zinc-800 bg-emerald-300 my-1 text-center flex items-center justify-center border-x border-zinc-700`}
              style={{width: getClassReturns(divsData.next_divs.Retorno_Dividendo[key])}}></div>
              <div className='text-zinc-200 ml-2 font-bold'>{divsData.next_divs.Retorno_Dividendo[key]}%</div>
            </div>
            
            <div className="py-1 flex-shrink-0 w-24 md:w-auto text-zinc-200 text-center flex items-center justify-center">{getValueNoInfo(divsData.next_divs.Promedio_Div_Ult_5Ans[key])}</div>
            <div className="py-1 flex-shrink-0 w-24 md:w-auto text-zinc-200 text-center flex items-center justify-center">{getValueNoInfo(divsData.next_divs.Radio_de_Pago_Divs[key])}</div>
            
          </div>
            ))}
          </> :
          <>
          <div className="pt-20 grid grid-cols-3 place-content-center text-white">
              <div className="text-center  flex items-center justify-center">...</div>
              <div className="text-center  flex items-center justify-center">... cargando ...</div>
              <div className="text-center  flex items-center justify-center">...</div>
          </div>
          </>
          }
        </div>
      </div>
    )
  }

export default ProxDivsTable