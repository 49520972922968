

export const formatTickText = (value) => {
    if (value >= 1e17) return `$${(value / 1e18).toFixed(1)}T`;  // Billón in Spanish
    if (value >= 1e15) return `$${(value / 1e15).toFixed(1)}MB`;  // Millón in Spanish
    if (value >= 1e11) return `$${(value / 1e12).toFixed(1)}B`;
    if (value >= 1e8) return `$${(value / 1e9).toFixed(1)}MM`;   // Mil millones in Spanish
    if (value >= 1e5) return `$${(value / 1e6).toFixed(1)}M`;   // Mil in Spanish
    if (value >= 1e2) return `$${(value / 1e3).toFixed(1)}K`; 
    return `$${value.toFixed(2)}`;
  };


  // Function to calculate the y-axis range and tick values
export const calculateTickValues = (values) => {
    const minValue = Math.min(...values);
    const maxValue = Math.max(...values);
    const range = maxValue - minValue;
    const lowerBound = minValue - range / 1.2;
    const upperBound = maxValue ;

    const tickVals = [];
    const tickTexts = [];
    const step = (upperBound - lowerBound) / 4;

    for (let i = 0; i <= 4; i++) {
        const tickValue = lowerBound + i * step;
        tickVals.push(tickValue);
        tickTexts.push(formatTickText(tickValue));
    }

    return { tickVals, tickTexts };
    };