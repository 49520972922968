import React, {useState, useEffect} from 'react';
import api from '../../Api_call';

import PlotSeriesSymb from './PlotSeriesSymb';
import PlotSeriesSymb2 from './PlotSeriesSymb2';
import PlotSeriesSymbFCF from './PlotSeriesSymbFCF';
import PlotSeriesSymb3 from './PlotSeriesSymb3';

function IndFinanz() {

    
    //toggle
    const[toggle,setToggle] = useState(1)
    function updateToggle(id){
        setToggle(id)
    }

    // format data


    function formatCurrency(amount, locale = 'en-US', currency = 'USD') {
        if (amount === "NoInfo") {
            return "No Info";
        }

        // Check if amount is in trillions
        if (Math.abs(amount) >= 1000000000000000000) {
            // Divide amount by one billion
            amount = amount / 1000000000000000000;
            // Format the amount with two decimal places
            const formattedAmount = new Intl.NumberFormat(locale, {
                style: 'currency',
                currency: 'COP',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            }).format(amount);
            // Append "B" to the formatted string
            return formattedAmount + "T";
        }
        // Check if amount is in billions
        if (Math.abs(amount) >= 1000000000000) {
            // Divide amount by one billion
            amount = amount / 1000000000000;
            // Format the amount with two decimal places
            const formattedAmount = new Intl.NumberFormat(locale, {
                style: 'currency',
                currency: 'COP',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            }).format(amount);
            // Append "B" to the formatted string
            return formattedAmount + "B";
        }
        // Check if amount is in millions
        else if (Math.abs(amount) >= 1000000) {
            // Divide amount by one million
            amount = amount / 1000000;
            // Format the amount with two decimal places
            const formattedAmount = new Intl.NumberFormat(locale, {
                style: 'currency',
                currency: 'COP',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            }).format(amount);
            // Append "M" to the formatted string
            return formattedAmount + "M";
        } else {
            // Format amount without truncating
            return new Intl.NumberFormat(locale, {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 1,
                maximumFractionDigits: 1
            }).format(amount);
        }
    }


    const getClassPerc = (value1) => {
        if (value1 === "NoInfo") {
            return "No Info"
        }else{
            var ValuePer = 100*parseFloat(value1)
            var percentage = ValuePer.toString().slice(0,5) + '%'
            return percentage; // Adjust the class for zero values
        }
      
      };

    const getPer = (value) => {
    if (value === "NoInfo") {
        return "0%"
    }else{
        var ValuePer = 100*parseFloat(value)
        if (ValuePer < 0){
            return "0%"
        }else{
        var percentage = ValuePer.toString().slice(0,5) + '%'
        return percentage; // Adjust the class for zero values
    }}
    };

    const getColorCreci = (value) => {
        
        if (value === "NoInfo") {
            return 'text-zinc-300';
        }
        else if (value > 0) {
          return 'text-emerald-500';
        } else {
          return 'text-red-500'; // Adjust the class for zero values
        }
      };
    
    const activatedStyle = {
        color: '#3b82f6', // Change this to the desired color
    };

    const activatedStyleGen = {
        color: '#27272a', // Change this to the desired color
        backgroundColor:'#a1a1aa',
    };

    

    //load data
    
    const [finzLoaded, setFinzLoaded] = useState(false)
    const [finzData, setFinzData] = useState(null)
    

    const fetchFinsData = async() => {
        const responseFinz = await api.get('/finantials/')
        setFinzData(responseFinz.data)
        setFinzLoaded(true)
     
    }

    //load graphic
    const [selectedKey, setSelectedKey] = useState(1)
    const [symbol, setSymbol] = useState('ecopetrol')
    const handleElementClick = (key) => {
        // Update state variable or trigger any function you want
        setSelectedKey(key);
        setSymbol(Object.values(finzData.bvc_query)[key])
    };

    useEffect(() => {
        if (finzData && Object.keys(finzData.bvc_query).length > 0) {
          const firstKey = Object.keys(finzData.bvc_query)[0];
          setSelectedKey(firstKey);
        }
      }, [finzData]);
    
    useEffect(() => {
        fetchFinsData();
    },[]);


    return (
      <div className="pb-1 text-zinc-200 max-w-7xl h-max mx-auto pt-2 md:pt-8 flex justify-center flex-col">
        {/*Tabs*/}
        <div className='text-xs md:text-base h-12'>
            <ul className='h-12 grid grid-cols-4 place-items-center text-zinc-300 font-bold'>
                <li 
                className={`h-full w-full py-2 rounded-t-lg text-center font-bold cursor-pointer bg-zinc-800 border-2 border-zinc-500 hover:bg-zinc-400 ${toggle === 1 ? 'active' : ''}`}
                style={toggle === 1 ? activatedStyleGen : null}
                onClick={()=>updateToggle(1)}>Ingresos</li>
                <li 
                className={`h-full w-full py-2 rounded-t-lg text-center font-bold cursor-pointer bg-zinc-800 border-2 border-zinc-500 hover:bg-zinc-400 ${toggle === 2 ? 'active' : ''}`}
                style={toggle === 2 ? activatedStyleGen : null}
                onClick={()=>updateToggle(2)}>Ganancias y Margenes</li>
                <li 
                className={`h-full w-full py-2 rounded-t-lg text-center font-bold cursor-pointer bg-zinc-800 border-2 border-zinc-500 hover:bg-zinc-400 ${toggle === 3 ? 'active' : ''}`}
                style={toggle === 3 ? activatedStyleGen : null}
                onClick={()=>updateToggle(3)}>Flujo de Caja libre</li>
                <li 
                className={`h-full w-full py-2 rounded-t-lg text-center font-bold cursor-pointer bg-zinc-800 border-2 border-zinc-500 hover:bg-zinc-400 ${toggle === 4 ? 'active' : ''}`}
                style={toggle === 4 ? activatedStyleGen : null}
                onClick={()=>updateToggle(4)}>Balance</li>
            </ul>
        </div>
        <div className='flex flex-col justify-center items-center pt-0 shadow-inner shadow-zinc-600 '>        
            {/*Tab de Ingresos*/}
            <div className={toggle===1? "block":"hidden"}>
                {/*Graficas*/}
                <div className='flex justify-center items-center w-auto'>
                    
                    <PlotSeriesSymb
                    symbol={symbol}
                    />

                </div>
                {/*tabla*/}
                <div className='max-w-7xl h-max mx-auto px-1 pt-1 md:pt-8 flex md:justify-center flex-col w-[385px] md:w-auto'>
                    <div className='text-xs md:text-base w-full overflow-x-scroll overflow-y-scroll md:overflow-x-hidden h-[300px] border border-zinc-600 rounded-md'>
                        <div className="top-0 sticky mr-6 flex md:grid md:grid-cols-4 md:place-content-center w-full">
                            <div className="p-2 sticky md:relative left-0 flex-shrink-0 w-24 md:w-auto z-20 bg-zinc-900 shadow-sm shadow-zinc-600 flex items-center justify-center text-zinc-200 font-extrabold text-center ">
                            Activo
                            </div>
                            <div className="p-2 flex-shrink-0 md:flex-grow w-32 md:w-auto bg-zinc-900 shadow-sm shadow-zinc-600 flex items-center justify-center text-zinc-200 font-extrabold text-center ">
                            Ingresos Totales Año
                            </div>
                            <div className="p-2 flex-shrink-0 md:flex-grow w-20 md:w-auto bg-zinc-900 shadow-sm shadow-zinc-600 flex items-center justify-center text-zinc-200 font-extrabold text-center ">
                            Crecimiento Ingresos Año
                            </div>
                            <div className="p-2 flex-shrink-0 md:flex-grow w-20 md:w-auto bg-zinc-900 shadow-sm shadow-zinc-600 flex items-center justify-center text-zinc-200 font-extrabold text-center ">
                            Ingresos Por Accion
                            </div>
                            {/* <div className="p-2 flex-shrink-0 md:flex-grow w-20 md:w-auto bg-zinc-900 shadow-sm shadow-zinc-600 flex items-center justify-center text-zinc-200 font-extrabold text-center ">
                            Valor Empresa /Ingresos
                            </div>
                            <div className="p-2 flex-shrink-0 md:flex-grow w-20 md:w-auto bg-zinc-900 shadow-sm shadow-zinc-600 flex items-center justify-center text-zinc-200 font-extrabold text-center ">
                            Precio/ Ventas Ult 12mses
                            </div> */}
                        </div>
                        {finzLoaded?
                        <>
                        {Object.keys(finzData.bvc_query).map((key, index) => (
                        <div className="flex md:grid md:grid-cols-4 md:place-content-center" key={index}>
                            <div className={`px-5 sticky md:relative flex-shrink-0 left-0 w-24 z-10 md:w-auto shadow-sm shadow-zinc-600  bg-zinc-900  py-1 font-extrabold text-zinc-200 flex items-center justify-center cursor-pointer ${selectedKey === key ? 'active' : ''}`}
                            style={selectedKey === key ? activatedStyle : null}
                            onClick={() => handleElementClick(key)}
                            >{finzData.bvc_query[key].toUpperCase()}</div>
                            <div className='flex-shrink-0 w-32 md:w-auto flex flex-row justify-between'>
                                <div className="pl-1 pr-3 font-bold py-1 text-right text-zinc-600">{finzData.ultimo_a_reportado[key]}</div>
                                <div className="pr-3 font-bold py-1 text-right text-zinc-200">{formatCurrency(finzData.Ingresos_Totales[key])}</div>
                            </div>
                            <div className={`py-1 flex-shrink-0 w-20 md:w-auto ${getColorCreci(finzData.Crecimiento_Ingresos[key])} text-center flex items-center justify-center`}>{getClassPerc(finzData.Crecimiento_Ingresos[key])}</div>
                            <div className="py-1 flex-shrink-0 w-20 md:w-auto text-zinc-200 text-center flex items-center justify-center">{formatCurrency(finzData.Ingresos_Por_Accion[key])}</div>
                            {/* <div className="py-1 flex-shrink-0 w-20 md:w-auto text-zinc-200 text-center flex items-center justify-center">{finzData.Valor_Empresa_Ingresos[key]}</div>
                            <div className="py-1 flex-shrink-0 w-20 md:w-auto text-zinc-200 text-center flex items-center justify-center">{finzData.Precio_Ventas_Ult_12mses[key]}</div> */}
                        </div>
                        ))}
                        
                        </> :
                        <>
                        <div className="pt-20 grid grid-cols-3 place-content-center text-white">
                            <div className="text-center  flex items-center justify-center">...</div>
                            <div className="text-center  flex items-center justify-center">... cargando ...</div>
                            <div className="text-center  flex items-center justify-center">...</div>
                        </div>
                        </>
                        }
                        
                    </div>
                </div>
                
            </div>
            {/*Tab de Margenes*/}
            <div className={toggle===2? "block":"hidden"}>
                {/*Graficas*/}
                <div className='flex justify-center items-center w-auto '>
                    <PlotSeriesSymb2
                    symbol={symbol}
                    />
                </div>
                {/*tabla*/}
                <div className='max-w-7xl h-max mx-auto px-1 pt-1 md:pt-8 flex md:justify-center flex-col w-[385px] md:w-auto'>
                    <div className='text-xs md:text-base w-full overflow-x-scroll overflow-y-scroll md:overflow-x-hidden h-[300px] border border-zinc-600 rounded-md'>
                        <div className="top-0 sticky mr-6 flex md:grid md:grid-cols-4 md:place-content-center w-full">
                            <div className="p-2 sticky md:relative left-0 flex-shrink-0 w-32 md:w-auto z-20 bg-zinc-900 shadow-sm shadow-zinc-600 flex items-center justify-center text-zinc-200 font-extrabold text-center ">
                            Activo
                            </div>
                            <div className="p-2 flex-shrink-0 md:flex-grow w-20 md:w-auto bg-zinc-900 shadow-sm shadow-zinc-600 border-b-4 border-rose-950 flex col-span-1 items-center justify-center text-zinc-200 font-extrabold text-center">
                            Ingresos Totales
                            </div>
                            <div className="p-2 flex-shrink-0 md:flex-grow w-20 md:w-auto bg-zinc-900 shadow-sm shadow-zinc-600 border-b-4 border-yellow-600 flex items-center justify-center text-zinc-200 font-extrabold text-center">
                            Margen Operacion
                            </div>
                            <div className="p-2 flex-shrink-0 md:flex-grow w-20 md:w-auto bg-zinc-900 shadow-sm shadow-zinc-600 border-b-4 border-emerald-600 flex items-center justify-center text-zinc-200 font-extrabold text-center">
                            Margen Neto
                            </div>
                        </div>
                        {finzLoaded?
                        <>
                        {Object.keys(finzData.bvc_query).map((key, index) => (
                        <div className="flex md:grid md:grid-cols-4 md:place-content-center">
                            <div className='sticky md:relative flex-shrink-0 left-0 w-32 z-10 md:w-auto shadow-sm shadow-zinc-600  bg-zinc-900 flex flex-row justify-between'>
                                <div className={`py-1 pl-1 md:pl-3 font-extrabold text-zinc-200 flex items-center justify-center cursor-pointer ${selectedKey === key ? 'active' : ''}`}
                                style={selectedKey === key ? activatedStyle : null}
                                onClick={() => handleElementClick(key)}
                                >
                                {finzData.bvc_query[key].toUpperCase()}</div>
                                <div className="pr-1 md:pt-1 pt-2 md:pr-3 font-bold py-1 text-right text-zinc-600">{finzData.ultimo_a_reportado[key]}</div>
                            </div>
                            <div className='flex-shrink-0 w-20 md:w-auto col-span-1 py-1'>
                                <div className='w-full h-full overflow-hidden bg-rose-950 border-2 rounded-full text-transparent grid grid-cols-1 justify-items-end'>
                                    {/* <div className='position:relative h-3 bg-yellow-600 text-left'
                                    style={{width: getPer(finzData.Margen_Bruto[key])}}></div> */}
                                    <div className='position:relative h-3 bg-yellow-600  text-left'
                                    style={{width: getPer(finzData.Margen_Operacion[key])}}></div>
                                    <div className='position:relative h-3 bg-emerald-600 text-left'
                                    style={{width: getPer(finzData.Margen_Neto[key])}}></div>

                                </div>

                            </div>
                            {/* <div className="py-1 text-zinc-200 text-center flex items-center justify-center">{getClassPerc(finzData.Margen_Bruto[key])}</div> */}
                            <div className="py-1 flex-shrink-0 w-20 md:w-auto text-zinc-200 text-center flex items-center justify-center">{getClassPerc(finzData.Margen_Operacion[key])}</div>
                            <div className="py-1 flex-shrink-0 w-20 md:w-auto text-zinc-200 text-center flex items-center justify-center">{getClassPerc(finzData.Margen_Neto[key])}</div>


                        </div>
                        ))}
                        
                        </> :
                        <>
                        <div className="flex md:grid md:grid-cols-4 md:place-content-center">
                            <div className="text-center  flex items-center justify-center">...</div>
                            <div className="text-center  flex items-center justify-center">... cargando ...</div>
                            <div className="text-center  flex items-center justify-center">...</div>
                        </div>
                        </>
                        }
                    </div>
                </div>
            </div>
            {/*Tab de CashFlow*/}
            <div className={toggle===3? "block":"hidden"}>
                {/*Graficas*/}
                <div className='flex justify-center items-center w-auto '>
                    
                    <PlotSeriesSymbFCF
                    symbol={symbol}
                    />

                </div>
                
                {/*tabla*/}
                <div className='max-w-7xl h-max mx-auto px-1 pt-1 md:pt-8 flex md:justify-center flex-col w-[385px] md:w-auto'>
                    <div className='text-xs md:text-base w-full overflow-x-scroll overflow-y-scroll md:overflow-x-hidden h-[300px] border border-zinc-600 rounded-md'>
                    <div className="top-0 sticky mr-6 flex md:grid md:grid-cols-3 md:place-content-center w-full">
                            <div className="p-2 flex-shrink-0 md:flex-grow w-[129px] md:w-auto bg-zinc-900 shadow-sm shadow-zinc-600  flex items-center justify-center text-zinc-200 font-extrabold text-center ">
                            Activo
                            </div>
                            <div className="p-2 flex-shrink-0 md:flex-grow w-[128px] md:w-auto bg-zinc-900 shadow-sm shadow-zinc-600 flex items-center justify-center text-zinc-200 font-extrabold text-center ">
                            Flujo de Caja Libre
                            </div>
                            <div className="p-2 flex-shrink-0 md:flex-grow w-[128px] md:w-auto bg-zinc-900 shadow-sm shadow-zinc-600 flex items-center justify-center text-zinc-200 font-extrabold text-center ">
                            Crecimiento FCF
                            </div>
                            
                        </div>

                        {finzLoaded?
                        <>
                        {Object.keys(finzData.bvc_query).map((key, index) => (
                        <div className="grid grid-cols-3 place-content-center">
                            <div className='flex-shrink-0 md:flex-grow w-[129px] md:w-auto flex flex-row justify-between'>
                                <div className={`py-1 pl-1 md:pl-3 font-extrabold text-zinc-200 flex items-center justify-center cursor-pointer ${selectedKey === key ? 'active' : ''}`}
                                style={selectedKey === key ? activatedStyle : null}
                                onClick={() => handleElementClick(key)}
                                >
                                {finzData.bvc_query[key].toUpperCase()}</div>
                                <div className="pr-1 md:pt-1 pt-2 md:pr-3 font-bold py-1 text-right text-zinc-600">{finzData.ultimo_a_reportado[key]}</div>
                            </div>
                            
                            {/* <div className="py-1 text-zinc-200 text-center flex items-center justify-center">{getClassPerc(finzData.Margen_Bruto[key])}</div> */}
                            <div className="py-1 flex-shrink-0 md:flex-grow w-[128px] md:w-auto text-zinc-200 text-center flex items-center justify-center">{formatCurrency(finzData.FCF[key])}</div>
                            <div className="py-1 flex-shrink-0 md:flex-grow w-[128px] md:w-auto text-zinc-200 text-center flex items-center justify-center">{getClassPerc(finzData.Crecimiento_FCF[key])}</div>


                        </div>
                        ))}
                        
                        </> :
                        <>
                        <div className="pt-20 grid grid-cols-3 place-content-center text-white">
                            <div className="text-center  flex items-center justify-center">...</div>
                            <div className="text-center  flex items-center justify-center">... cargando ...</div>
                            <div className="text-center  flex items-center justify-center">...</div>
                        </div>
                        </>
                        }
                        
                    </div>
                </div>
            </div>
            {/*Tab de Balance*/}
            <div className={toggle===4? "block":"hidden"}>
                {/*Graficas*/}
                <div className='flex justify-center items-center w-auto '>
                    
                    <PlotSeriesSymb3
                    symbol={symbol}
                    />

                </div>
                
                {/*tabla*/}
                <div className='max-w-7xl h-max mx-auto px-1 pt-1 md:pt-8 flex md:justify-center flex-col w-[385px] md:w-auto'>
                    <div className='text-xs md:text-base w-full overflow-x-scroll overflow-y-scroll md:overflow-x-hidden h-[300px] border border-zinc-600 rounded-md'>
                        <div className="top-0 sticky mr-6 flex md:grid md:grid-cols-5 md:place-content-center w-full">
                            <div className="p-2 sticky md:relative left-0 flex-shrink-0 w-32 md:w-auto z-20 bg-zinc-900 shadow-sm shadow-zinc-600 flex items-center justify-center text-zinc-200 font-extrabold text-center ">
                            Activo
                            </div>
                            <div className="p-2 flex-shrink-0 md:flex-grow w-28 md:w-auto bg-zinc-900 shadow-sm  shadow-zinc-600 flex items-center justify-center text-zinc-200 font-extrabold text-center ">
                            Activos Totales
                            </div>
                            <div className="p-2 flex-shrink-0 md:flex-grow w-28 md:w-auto bg-zinc-900 shadow-sm  shadow-zinc-600 flex items-center justify-center text-zinc-200 font-extrabold text-center ">
                            Pasivos Totales
                            </div>
                            <div className="p-2 flex-shrink-0 md:flex-grow w-28 md:w-auto bg-zinc-900  shadow-sm shadow-zinc-600 flex items-center justify-center text-zinc-200 font-extrabold text-center ">
                            Patrimonio
                            </div>
                            <div className="p-2 flex-shrink-0 md:flex-grow w-28 md:w-auto bg-zinc-900 shadow-sm  shadow-zinc-600 flex items-center justify-center text-zinc-200 font-extrabold text-center ">
                            Crecimiento Patrimonio
                            </div>
                            
                        </div>
                        {finzLoaded?
                        <>
                        {Object.keys(finzData.bvc_query).map((key, index) => (
                        <div className="flex md:grid md:grid-cols-5 md:place-content-center">
                            <div className='sticky md:relative flex-shrink-0 left-0 w-32 z-10 md:w-auto shadow-sm shadow-zinc-600  bg-zinc-900 flex flex-row justify-between'>
                                <div className={`py-1 pl-1 md:pl-3 font-extrabold text-zinc-200 flex items-center justify-center cursor-pointer ${selectedKey === key ? 'active' : ''}`}
                                style={selectedKey === key ? activatedStyle : null}
                                onClick={() => handleElementClick(key)}
                                >
                                {finzData.bvc_query[key].toUpperCase()}</div>
                                <div className="pr-1 md:pt-1 pt-2 md:pr-3 font-bold py-1 text-right text-zinc-600">{finzData.ultimo_a_reportado[key]}</div>
                            </div>
                            
                            {/* <div className="py-1 text-zinc-200 text-center flex items-center justify-center">{getClassPerc(finzData.Margen_Bruto[key])}</div> */}
                            <div className="py-1 flex-shrink-0 w-28 md:w-auto text-zinc-200 text-center flex items-center justify-center">{formatCurrency(finzData.Activos[key])}</div>
                            <div className="py-1 flex-shrink-0 w-28 md:w-auto text-zinc-200 text-center flex items-center justify-center">{formatCurrency(finzData.Pasivos[key])}</div>
                            <div className="py-1 flex-shrink-0 w-28 md:w-auto text-zinc-200 text-center flex items-center justify-center">{formatCurrency(finzData.Patrimonio[key])}</div>
                            <div className="py-1 flex-shrink-0 w-28 md:w-auto text-zinc-200 text-center flex items-center justify-center">{getClassPerc(finzData.Crecimiento_Patrimonio[key])}</div>

                        </div>
                        ))}
                        
                        </> :
                        <>
                        <div className="pt-20 grid grid-cols-3 place-content-center text-white">
                            <div className="text-center  flex items-center justify-center">...</div>
                            <div className="text-center  flex items-center justify-center">... cargando ...</div>
                            <div className="text-center  flex items-center justify-center">...</div>
                        </div>
                        </>
                        }
                        
                    </div>
                </div>
            </div>
        </div>
        
        
        
        
        
      </div>
    )
  }

export default IndFinanz