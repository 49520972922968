import Plot from 'react-plotly.js';
import React, {useState, useEffect} from 'react';

function PlotBarsPer2({years_list1, data1, years_list2, data2}) {

  const [years1, setYears1] = useState(null);
  const [values1, setValues1] = useState(null)
  const [years2, setYears2] = useState(null);
  const [values2, setValues2] = useState(null)
    

    useEffect(() => {
        if (data1&&data2)
        {
          setYears1(years_list1)
          setValues1(data1)
          setYears2(years_list2)
          setValues2(data2)
        }
      }, [data1]);

    return (
      <div className="mx-auto flex justify-center w-[375px] md:w-auto"
      style={{ 'paddingTop':'1px', 'paddingBottom':'1px',
                          'marginTop':'1px',
                          display: 'flex', justifyContent: 'center' }}>
        <Plot
            data={[
              {
                x: years1,
                y: data1,
                name: 'Creci. Ganancis',
                type: 'scatter',
                /* offset: -0.4, // Shift the bars to the left
                width: 0.4, */
                marker: {
                  color: '#a1a1aa', // Red color with opacity 0.7
                  
                },
              },
              {
                x: years2,
                y: data2,
                yaxis: 'y2',
                name: 'Creci. Margen',
                type: 'scatter',
             /*    offset: 0.0,
                width: 0.4, */
                marker:{
                  color:"#059669",
                  
                }
              }
            ]}
            layout={{ 
              dragmode: 'pan',
              xaxis: {
                autorange: true, 
                tickformat: '%Y', // Format string for day-month-year
                //dick: 30*24*60*60*1000,
                domain: [0, 1],
                //type: 'date',
                tickfont: {color: 'white'},
                //range : ['2024-01-01','2024-02-01'],
                //range : [`'${startPlot}'`,`'${endPlot}'`],
                rangeslider: {
                    visible: false
                }
              },

              yaxis: {
                showtitle: false,
                side: 'left',
                titlefont: {color: '#a1a1aa'},
                tickfont: {color: '#a1a1aa'},
                position: 0,
                tickformat: ',.0%'
              },

              yaxis2: {
                showtitle: false,
                overlaying: 'y',
                anchor: 'free',
                side: 'right',
                position: 1,
                tickfont: {color: '#059669'},
                tickformat: ',.0%'
              },
    
              //width: `600`,
              //heigth: `300`, 
              autosize: true,
              hovermode:"x unified",
              showlegend:false,
              legend: {
                x: 0.66, // Adjust the horizontal position of the legend inside the plot
                y: 1.3, // Adjust the vertical position of the legend inside the plot
              },
              margin: {
                l: 50, // Left margin
                r: 50, // Right margin
                t: 15, // Top margin
                b: 40, // Bottom margin
              },
              paper_bgcolor:"#18181b",
              plot_bgcolor:"#18181b",
              }}

              config={{ responsive: true, displayModeBar: false}}
              className='h-[200px] sm:h-[200px] md:h-[280px] lg:h-[300px] w-[375px] md:w-[365px]  lg:w-[490px] xl:w-[600px]'
              
            />
      </div>
    )
  }

export default PlotBarsPer2