//import { ToastContainer } from 'react-toastify';
//import 'react-toastify/dist/ReactToastify.css';

//import { check_authenticated, load_user, refresh } from '../redux/actions/auth';

import { useEffect } from 'react';

import Navbar from '../components/navigation/Navbar';
import Footer from '../components/navigation/Footer';

const Layout = (props) => {

    useEffect(() => {

    }, []);

    return(
        <div>
            <Navbar/>
            {props.children}
            <Footer/>
        </div>
    )
}


export default Layout