import React, {useState, useEffect} from 'react';
import api from '../../Api_call';


function BarsStats() {

    const [barsLoaded, setBarsLoaded] = useState(false)
    const [barsData, setBarsData] = useState(null)

    const getPer = (value) => {
        var percentage = value + '%'
        return percentage; // Adjust the class for zero values
        
      };

    const fetchBarsData = async() => {
        const responseBars = await api.get('/niveles_stats/')
        setBarsData(responseBars.data)
        setBarsLoaded(true)
    }

    useEffect(() => {
        fetchBarsData();

    },[]);

    return (
      <div className="max-w-7xl h-max mx-auto px-1 pt-2 md:pt-8 flex justify-center flex-col">
        <div className='grid text-[11px]/[15px] lg:text-sm grid-cols-2 lg:grid-cols-4 gap-1 md:gap-6'>
            {barsLoaded?
            <>  {/* prcio aumenta disminuye */}
                <div className='border border-zinc-600 rounded-lg p-2 grid grid-cols-1'>
                    <div className='w-full flex justify-between pb-2'>
                        <div className='flex flex-col items-start'>
                            <p className='text-emerald-500 font-bold'>Aumentando</p>
                            <p className='text-emerald-500 font-bold'>{barsData.c_aumentando_per}% ({barsData.c_aumentando})</p>
                        </div>
                        <div className='flex flex-col items-end'>
                            <p className='text-red-500 font-bold'>Disminuyendo</p>
                            <p className='text-red-500 font-bold'>{barsData.c_disminuyendo_per}% ({barsData.c_disminuyendo})</p>
                        </div>

                    </div>
                    <div className='flex h-3 flex-row'>
                        <div className='bg-emerald-500 text-transparent rounded-l-sm' 
                        style={{width: getPer(barsData.c_aumentando_per)}}></div>
                        <div className='bg-zinc-800 text-transparent rounded-r-sm'
                        style={{width: getPer(barsData.c_otro_per)}}>-</div>
                        <div className='bg-red-500 text-transparent rounded-r-sm'
                        style={{width: getPer(barsData.c_disminuyendo_per)}}>-</div>

                    </div>
                </div>
                {/* fuerza */}
                <div className='border border-zinc-600 rounded-lg p-2 grid grid-cols-1'>
                    <div className='w-full flex justify-between pb-2'>
                        <div className='flex flex-col items-start w-1/3'>
                            <p className='text-blue-600 font-bold'>Sobrecomprados</p>
                            <p className='text-blue-600 font-bold'>{barsData.f_sobrecompra_per}% ({barsData.f_sobrecompra})</p>
                        </div>
                        <div className='flex flex-col items-center w-1/3 place-content-end'>
                            <p className='text-zinc-400 font-bold'>FUERZA</p>
                        </div>
                        <div className='flex flex-col items-end w-1/3'>
                            <p className='text-yellow-500 font-bold'>Sobrevendidos</p>
                            <p className='text-yellow-500 font-bold'>{barsData.f_sobreventa_per}% ({barsData.f_sobreventa})</p>
                        </div>

                    </div>
                    <div className='flex h-3 flex-row'>
                        <div className='bg-blue-600 text-transparent rounded-l-sm' 
                        style={{width: getPer(barsData.f_sobrecompra_per)}}></div>
                        <div className='bg-zinc-800 text-transparent rounded-r-sm'
                        style={{width: getPer(barsData.f_otro_per)}}>-</div>
                        <div className='bg-yellow-500 text-transparent rounded-r-sm'
                        style={{width: getPer(barsData.f_sobreventa_per)}}>-</div>

                    </div>
                </div>
                {/* SMA50 */}
                <div className='border border-zinc-600 rounded-lg p-2 grid grid-cols-1'>
                    <div className='w-full flex justify-between pb-2'>
                        <div className='flex flex-col items-start w-1/3'>
                            <p className='text-emerald-500 font-bold'>Arriba</p>
                            <p className='text-emerald-500 font-bold'>{barsData.sm50_arriba_per}% ({barsData.sm50_arriba})</p>
                        </div>
                        <div className='flex flex-col items-center w-1/3 place-content-end'>
                            <p className='text-zinc-400 font-bold'>SMA50</p>
                        </div>
                        <div className='flex flex-col items-end w-1/3'>
                            <p className='text-red-500 font-bold'>Abajo</p>
                            <p className='text-red-500 font-bold'>{barsData.sm50_abajo_per}% ({barsData.sm50_abajo})</p>
                        </div>

                    </div>
                    <div className='flex h-3 flex-row'>
                        <div className='bg-emerald-500 text-transparent rounded-l-sm' 
                        style={{width: getPer(barsData.sm50_arriba_per)}}></div>
                        <div className='bg-red-500 text-transparent rounded-r-sm'
                        style={{width: getPer(barsData.sm50_abajo_per)}}>-</div>

                    </div>
                </div>
                {/* SMA200 */}
                <div className='border border-zinc-600 rounded-lg p-2 grid grid-cols-1'>
                    <div className='w-full flex justify-between pb-2'>
                        <div className='flex flex-col items-start w-1/3'>
                            <p className='text-emerald-500 font-bold'>Arriba</p>
                            <p className='text-emerald-500 font-bold'>{barsData.sm200_arriba_per}% ({barsData.sm200_arriba})</p>
                        </div>
                        <div className='flex flex-col items-center w-1/3 place-content-end'>
                            <p className='text-zinc-400 font-bold'>SMA200</p>
                        </div>
                        <div className='flex flex-col items-end w-1/3'>
                            <p className='text-red-500 font-bold'>Abajo</p>
                            <p className='text-red-500 font-bold'>{barsData.sm200_abajo_per}% ({barsData.sm200_abajo})</p>
                        </div>

                    </div>
                    <div className='flex h-3 flex-row'>
                        <div className='bg-emerald-500 text-transparent rounded-l-sm' 
                        style={{width: getPer(barsData.sm200_arriba_per)}}></div>
                        <div className='bg-red-500 text-transparent rounded-r-sm'
                        style={{width: getPer(barsData.sm200_abajo_per)}}>-</div>

                    </div>
                </div>
            </>
            :
            <></>
            }
        </div>
      </div>
    )
  }

export default BarsStats