import Plot from 'react-plotly.js';
import React, {useState, useEffect} from 'react';
import { calculateTickValues } from '../helpers/helpersCharts'

function PlotBars({years_list, data, name}) {

    const [years, setYears] = useState(null);
    const [values, setValues] = useState(null)
    
//

    useEffect(() => {
        if (data)
        {
            setYears(years_list)
            setValues(data)
            
        }
      }, [data]);

    const { tickVals, tickTexts } = values ? calculateTickValues(data) : { tickVals: [], tickTexts: [] };


    return (
      <div className="mx-auto flex justify-center w-[375px] md:w-auto"
      style={{ 'paddingTop':'1px', 'paddingBottom':'1px',
                          'marginTop':'1px',
                          display: 'flex', justifyContent: 'center' }}>
        <Plot
            data={[
              {
                x: years,
                y: values,
                type: 'bar',
                xaxis: 'x',
                yaxis: 'y',
                name: `${name}`, // Name for the bar plot
                marker: {
                  color: '#3b82f6', // Red color with opacity 0.7
                },
                //customdata: values.map(value => formatHoverText(value)),
                //hovertemplate: `%{x}: %{customdata}<extra></extra>`,
              },
              {
                x: years,
                y: values,
                type: 'scatter', // Line plot
                mode: 'lines', // Show line only, no markers
                xaxis: 'x',
                yaxis: 'y',
                name: `${name}`,
                line: {
                  color: 'white', // Blue color
                },
                //hovertemplate: hovertemplate,
              },
            ]}

            layout={{ 
              dragmode: 'pan',
              xaxis: {
                autorange: true, 
                tickformat: '%Y', // Format string for day-month-year
                //dick: 30*24*60*60*1000,
                domain: [0, 1],
                //type: 'date',
                tickfont: {color: 'white'},
                //range : ['2024-01-01','2024-02-01'],
                //range : [`'${startPlot}'`,`'${endPlot}'`],
                rangeslider: {
                    visible: false
                }
              },

              yaxis: {
                showtitle: false,
                side: 'left',
                titlefont: {color: '#a1a1aa'},
                tickfont: {color: '#a1a1aa'},
                tickformat: '.3',
                tickmode : 'array',
                tickvals : tickVals,
                ticktext : tickTexts,
             
              },
    
              //width: `600`,
              //heigth: `300`, 
              autosize: true,
              hovermode:"x unified",
              showlegend:false,
              legend: {
                x: 0.66, // Adjust the horizontal position of the legend inside the plot
                y: 1.3, // Adjust the vertical position of the legend inside the plot
              },
              margin: {
                l: 60, // Left margin
                r: 15, // Right margin
                t: 15, // Top margin
                b: 40, // Bottom margin
              },
              paper_bgcolor:"#18181b",
              plot_bgcolor:"#18181b",
            }}
            
            
            config={{ responsive: true, displayModeBar: false,}}
            className='h-[200px] sm:h-[200px] md:h-[280px] lg:h-[300px] w-[375px] md:w-[365px]  lg:w-[490px] xl:w-[600px]'
            />
      </div>
    )
  }

export default PlotBars